<template>
  <VirtualList
    :data-key="keyField"
    :data-sources="itemsWithKeyField"
    :data-component="EmptyComponent"
    :class="{ edit: edit}"
    :keeps="keeps"
    :estimate-size="itemSize"
    class="vue-scroll-list table_scroller"
    wrap-class="goods_table"
    ref="scroller"
  >
    <template #header>
      <slot name="before" ref="before"></slot>
    </template>
    <template #item="{ item, index }">
      <slot :item="item" :index="index" :width="width">
      </slot>
    </template>
  </VirtualList>
</template>

<script>
import _ from 'lodash';
import VirtualList from 'vue-virtual-scroll-list';
// import { RecycleScroller } from 'vue-virtual-scroller';
import { generateUuid } from '@/utils/searchUtil.js';
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
  name: 'TwTableScrollerList',
  components: {
    // RecycleScroller,
    VirtualList,
  },
  props: {
    items: Array,
    itemSize: Number,
    keyField: String,
    keeps: Number,
    edit: Boolean,
    maxWidth: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      width: 0,
      EmptyComponent: {},
    }
  },
  computed: {
    itemsWithKeyField() {
      if (!this.items.length) {
        return this.items;
      }
      if (this.edit) {
        return this.items.map(item => {
          return {
            ...item,
            keyField: item.keyField || generateUuid(),
          };
        });
      }
      return _.cloneDeep(this.items.map(item => {
        return {
          ...item,
          keyField: item.keyField || generateUuid(),
        };
      }));
    }
  },
  mounted() {
    this.$nextTick(() => {
      const $thead = this.$el.querySelector('thead');
      const $wrapper = this.$el.querySelector('[role="group"]');
      const $processList = this.$el.closest('.tw_process_list');
      const PADDING = 47; // process_listの幅から引くpaddingやborderの合計値
      $wrapper.prepend($thead);
      this.width = $processList.clientWidth - PADDING - this.$store.state.scrollbarWidth;
      window.addEventListener('resize', this.onResize, false);
      this.onResize();
      // ヘッダーのスクロール制御
      this.$refs.scroller.$el.addEventListener('scroll', this.headerScroll);
      document.addEventListener('scroll', this.headerScroll);
    })
  },
  beforeDestroy() {
    this.$refs.scroller.$el.removeEventListener('scroll', this.headerScroll);
    document.removeEventListener('scroll', this.headerScroll);
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    headerScroll() {
      this.$emit('headerScroll', this.$refs.scroller.$el.scrollLeft);
    },
    onResize() {
      const el = _.get(this, '$refs.scroller.$el');
      el.style.maxWidth = this.maxWidth;
    },
  }
}
</script>
<style lang="scss">
.vue-scroll-list.table_scroller {
  background-color: white;
  width: 100%;
  max-height: 275px;
  overflow: scroll;
  &.edit {
    max-width: 944px;
    max-height: 370px;
    background: url('../../assets/images/loading.gif') no-repeat center center;
    background-size: 8%;
  }

  .goods_table {
    margin-top: 0;
    border-collapse: collapse;
    table-layout: fixed;
    // background: white;

    [role="listitem"] {
      background-color: white;
      display: table;
      width: 100%;
      tbody {
        width: 100%;
      }
      thead {
        display: none;
      }
      &:last-child {
        border-bottom: 1px solid #EDEDF4;
      }
    }
    // .scroll_header {
    //   position: sticky;
    // }
  }
}
</style>
