<template>
  <div v-if="visible" class="tw_process_group_list" :class="{open: open}">
    <div class="inner" @click="toggle">
      <img class="arrow" src="@/assets/images/icons/accordion_arrow.svg">
      <div class="group_name">{{group.name}}</div>
    </div>
    <transition
      name="content"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-show="open" class="collapse">
        <!-- プロセス登録 -->
          <template v-if="isEditMode">
            <div class="twoColumn">
              <TwProcessGroupListEditIpRequest
                v-for="item in group.children"
                :key="item.key"
                :item="item"
                :tableName="tableName"
                :groupName="group.variableName"
                :arrayName="'ipreqList'"
                :keyName="item.key"
                v-model="localValue"
                :selectIndex="selectIndex"
                :selectIpreq="selectIpreq"
                :serialIndexes="serialIndexes"
                :serialItems="serialItems"
                @add-index="addIndex"
                :lineErrors="lineErrors"
                :isInsurance="isInsurance"
              />
            </div>
          </template>
          <!-- プロセス詳細 -->
          <template v-else>
            <div class="twoColumn">
              <TwProcessGroupListItemIpRequest
                v-for="item in group.children"
                :key="item.key"
                :item="item"
                :tableName="tableName"
                :groupName="group.variableName"
                :arrayName="'ipreqList'"
                :keyName="item.key"
                v-model="localValue"
                :selectIndex="selectIndex"
                :selectIpreq="selectIpreq"
                :serialIndexes="serialIndexes"
                :serialItems="serialItems"
                @add-index="addIndex"
              />
            </div>
          </template>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { RESPONSIBLE_FLG } from 'lib-tw-common';
import { collapseTransition } from '@/utils/nextFrame';
import TwProcessGroupListItemIpRequest from '@/components/molecules/TwProcessGroupListItemIpRequest';
import TwProcessGroupListEditIpRequest from '@/components/molecules/TwProcessGroupListEditIpRequest';
import { serialGrpName, getSerialGrpItems, getSerialGrpIndex, getKey } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListIpRequest',
  props: {
    group: Object,
    tableName: String,
    isEditMode: Boolean,
    value: Object,
    selectIndex: Number,
    selectIpreq: Object,
    isInsurance: Boolean,
  },
  inject: ['s'],
  components: {
    // TwButton,
    TwProcessGroupListItemIpRequest,
    TwProcessGroupListEditIpRequest
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.isEditMode || _.get(this, 's.$refs.processDetail.expand'),
      serialIndexes: {}, // 連番項目グループindex
      serialItems: {}, // 連番項目グループ
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    visible() {
      return _.some(this.group.children, item => {
        return item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'] && item.controlType !== false;
      });
    },
    isSerialGrp() { // 連番項目グループがあるかどうか
      return serialGrpName.includes(this.group.variableName);
    },
    // 行毎のエラー
    lineErrors() {
      if (this.isEditMode) {
        return _.filter(this.s.errors, e => {
          return e && _.isString(e) ? e.startsWith(`processSeparate.ipreqList[${this.selectIndex}]`) : false;
        });
      } else {
        return [];
      }
    }
  },
  watch: {
    's.res': {
      handler() {
        this.initSerial();
      },
    },
  },
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
    this.initSerial(); // 連番項目init処理
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openAll() {
      this.open = true;
    },
    closeAll() {
      this.open = false;
    },
    initSerial() { // remarks等の連番項目をグループ化し可変にする
      if (this.isSerialGrp) {
        const res = this.isEditMode ? _.get(this, `s.cloneItems[${this.tableName}].ipreqList[${this.selectIndex}].${this.group.variableName}`, {}) : _.get(this.s.res, `${this.tableName}.ipreqList[${this.selectIndex}].${this.group.variableName}`, {});
        this.serialItems = getSerialGrpItems(res, this.group.variableName);
        if (Object.keys(this.serialItems).length > 0) this.serialIndexes = getSerialGrpIndex(this.serialItems);
      }
    },
    addIndex(name, num) { // 連番項目を追加 or 削除する
      const key = getKey(name);
      this.serialIndexes[key] += num;
    },
  },
};
</script>

<style lang="scss" scoped>
  .tw_process_group_list {
    .inner {
      display: flex;
      align-items: center;
      padding: 22px 18px 10px 0;
      cursor: pointer;

      img {
        display: block;
        width: 12px;
        height: 12px;
        transition: transform .3s;
      }

      .group_name {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin-left: 8px;
      }

      .tw_button {
        padding-right: 17px;
        padding-left: 17px;
        margin-left: auto;
      }
    }

    .collapse {
      border-top: 1px solid #EDEDF4;
      padding: 12px 0 10px;
    }

    &.open {
      .inner img.arrow {
        transform: rotate(90deg);
      }
    }
  }

  .content-enter-active,
  .content-leave-active {
    transition: height .3s;
  }

  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;

    .edit_document_payment_terms_hr {
      display: block;
      width: 100%;
      height: 1px;
      background: $color_gray_300;
      margin: 0 0 24px;
      border: 0;
    }

    .document_payment_terms_hr {
      display: block;
      width: 100%;
      height: 1px;
      background: $color_gray_300;
      margin: 0 0 12px;
      border: 0;
    }
  }
</style>
