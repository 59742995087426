<template>
  <div
    class="tw_process_group_list_edit_item"
    :class="{add_serial: isAddSerial && !serialGroupName, remove_serial: isRemoveSerial && !serialGroupName, full: item.inputType === 'text_full' || item.inputType === 'textarea', border_top: (keyName.includes('DescriptionOfGoods') && keyName !== 'ipreqDescriptionOfGoods01') || (keyName.includes('ipreqQuantity1') && keyName !== 'ipreqQuantity1_01')}"
  >
    <el-form-item
      v-if="isVisible && isSerialItem && isNestItem"
      :label="item.label"
      :prop="`${tableName}.${arrayName}[${selectIndex}].${groupName}.${keyName}`"
      :rules="rules"
    >
      <template>
        <!-- テキストエリア -->
        <el-input
          v-if="item.inputType === 'textarea'"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6}"
          placeholder=""
          v-model="inputModel"
          :disabled="isReadonly"
          show-word-limit
          :maxlength="getMaxLength"
        />
        <!-- 数値 -->
        <TwInputFormatNumber
          v-else-if="item.inputType === 'number'"
          v-model="numberModel"
          :disabled="isReadonly"
        />
        <!-- 数量 単位 -->
        <template v-else-if="item.inputType === 'quantity'">
          <TwInputFormatNumber
            v-model="numberModel"
            class="narrow"
            :disabled="isReadonly"
          />
          <TwSelect class="unit" v-model="unitModel" @change="onUnitChange" filterable allow-create default-first-option clearable :disabled="isReadonly">
            <el-option
              v-for="unit in SYS_MST_UNIT"
              :key="unit.id"
              :label="unit.value"
              :value="unit.value">
            </el-option>
          </TwSelect>
        </template>
        <!-- 金額 通貨 -->
        <template v-else-if="item.inputType === 'currency'">
          <TwInputFormatNumber
            v-model="numberModel"
            class="narrow"
            :disabled="isReadonly"
          />
          <TwSelect class="unit" v-model="localValue[tableName][arrayName][selectIndex][groupName][getTarget()]" @change="onCurrencyChange" filterable allow-create default-first-option clearable :disabled="isReadonly">
            <el-option
              v-for="unit in SYS_MST_CURRENCY"
              :key="unit.alphabeticCode"
              :label="unit.symbol"
              :value="unit.symbol">
            </el-option>
          </TwSelect>
        </template>
        <!-- 港湾、地名 -->
        <TwPortAndPlace v-else-if="item.code === 'SYS_MST_PORT_AND_PLACE'" v-model="inputModel" @input="onPlaceChange" :disabled="isReadonly" />
        <!-- 主たる輸送用具 -->
        <el-select v-else-if="item.code === 'IPDN_CONVEYANCE'" v-model="pullDownModel" filterable clearable :disabled="isReadonly">
          <el-option
            v-for="unit in IPDN_CONVEYANCE_VARIABLES"
            :key="unit.code"
            :label="unit.label"
            :value="unit.code">
          </el-option>
        </el-select>
        <!-- 貿易条件区分 -->
        <el-radio-group v-else-if="item.code === 'DELIVERY_TERMS'" v-model="inputModel" :disabled="isReadonly">
          <el-radio v-for="radio in DELIVERY_TERMS_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
        </el-radio-group>
        <!-- I/P発行依頼輸出入区分 -->
        <el-radio-group v-else-if="item.code === 'REQUEST_IP_TRADE_CATEGORY'" v-model="inputModel" :disabled="isReadonly">
          <el-radio v-for="radio in REQUEST_IP_TRADE_CATEGORY_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
        </el-radio-group>
        <!-- I/P発行依頼DN種別 -->
        <el-radio-group v-else-if="item.code === 'REQUEST_IP_DN_TYPE'" v-model="inputModel" :disabled="isReadonly">
          <el-radio v-for="radio in REQUEST_IP_DN_TYPE_VARIABLES" :key="radio.code" :label="radio.code" border @click.native.prevent="onRadioClick(radio.code, item.key)">{{radio.label}}</el-radio>
        </el-radio-group>
        <!-- 日付 数値 -->
        <el-date-picker
          v-else-if="item.inputType === 'dateNumber'"
          style="width:320px"
          v-model="dateNumberModel"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder=""
          :disabled="isReadonly"
        />
        <el-input v-else-if="item.inputType === 'text_full'" class="text_full show_word_limit" style="width: 100%" v-model="inputModel" placeholder="" show-word-limit :maxlength="getMaxLength" :disabled="isReadonly" />
        <el-input v-else style="width: 320px" v-model="inputModel" class="show_word_limit" placeholder="" :disabled="isReadonly" show-word-limit  :maxlength="getMaxLength" />

        <template v-if="!isReadonly">
          <!-- × 連番項目削除ボタン -->
          <img class="remove_button" src="@/assets/images/icons/times.svg" v-if="isRemoveSerial && !serialGroupName" @click="addIndex(-1)">
          <!-- + 連番項目追加ボタン -->
          <tw-button type="secondary" size="medium" icon="plus" v-if="isAddSerial && !serialGroupName" @click="addIndex(1)" class="add_button" :class="{group_button: isGroupButton}"></tw-button>
        </template>
      </template >
    </el-form-item>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';
import { RESPONSIBLE_FLG, PROCESS_TYPE, DELIVERY_TERMS_VARIABLES, REQUEST_IP_TRADE_CATEGORY_VARIABLES, REQUEST_IP_DN_TYPE_VARIABLES, IPDN_CONVEYANCE_VARIABLES, IP_REQUEST_TYPE, IP_SUBMIT_FLG, REQUEST_IP_TRADE_CATEGORY } from 'lib-tw-common';
import { getMaxLength, onDateChange } from '@/utils/searchUtil.js';
import TwButton from '@/components/atoms/TwButton';
// import TwInputNumberMax from '@/components/atoms/TwInputNumberMax';
import TwInputFormatNumber from '@/components/atoms/TwInputFormatNumber';
// import TwInputFormatNumberString from '@/components/atoms/TwInputFormatNumberString';
import TwPortAndPlace from '@/components/atoms/TwPortAndPlace';
import TwSelect from '@/components/atoms/TwSelect';
import { getKey, getSerialIndex, getItemIndex, getMaxIndex } from '@/utils/processedSerialItems';
import { changeTradeCategory } from '@/utils/ipRequestUtils';

BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export default {
  name: 'TwProcessGroupListEditIpRequest',
  props: {
    item: Object,
    tableName: String,
    arrayName: String,
    groupName: String,
    keyName: String,
    value: Object,
    selectIndex: Number,
    serialIndexes: Object,
    serialItems: Object,
    readonly: Boolean,
    lineErrors: Array,
    isInsurance: Boolean
  },
  inject: ['s', 'tab', 'activeTab'],
  components: {
    TwButton,
    // TwInputNumberMax,
    TwInputFormatNumber,
    // TwInputFormatNumberString,
    TwPortAndPlace,
    TwSelect
  },
  data() {
    return {
      checks: [],
      DELIVERY_TERMS_VARIABLES,
      REQUEST_IP_TRADE_CATEGORY_VARIABLES,
      REQUEST_IP_DN_TYPE_VARIABLES,
      IPDN_CONVEYANCE_VARIABLES
    };
  },
  filters: {
    nullFormat: value => {
      if (value === null || '') {
        return undefined;
      }
      if (_.isNaN(Number(value))) {
        return undefined;
      }
      return Number(value);
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    model() {
      return this.localValue[this.tableName][this.arrayName][this.selectIndex][this.groupName][this.item.key];
    },
    inputModel: {
      get() {
        return this.model;
      },
      set(value) {
        this.setValue(value);
      }
    },
    numberModel: {
      get() {
        return this.nullFormat(this.model);
      },
      set(value) {
        if (value === undefined) {
          this.setValue(null);
        } else {
          this.setValue(value);
        }
      }
    },
    dateNumberModel: {
      get() {
        if (dayjs(String(this.model)).isValid()) {
          return dayjs(String(this.model)).format('YYYY-MM-DD');
        } else {
          return '';
        }
      },
      set(value) {
        if (!value) {
          this.setValue(null);
        } else {
          this.setValue(Number(dayjs(value).format('YYYYMMDD')));
        }
      }
    },
    unitModel: {
      get() {
        const unitTarget = _.get(this, `localValue[${this.tableName}][${this.arrayName}][${this.selectIndex}][${this.groupName}][${this.getTarget()}]`)
        return _.get(_.find(this.SYS_MST_UNIT, { code: unitTarget }), 'value');
      },
      set(value) {
        const unitId = _.get(_.find(this.SYS_MST_UNIT, { value: value }), 'code');
        if (unitId || unitId === 0) {
          this.setTargetValue(this.getTarget(), unitId);
        } else {
          this.setTargetValue(this.getTarget(), null);
        }
      }
    },
    pullDownModel: {
      get() {
        return this.model;
      },
      set(value) {
        if (!value) {
          this.setValue(null);
        } else {
          this.setValue(value);
        }
      }
    },
    isVisible() {
      if (!this.localValue) {
        return false;
      }
      if (this.item.hide) {
        return false;
      }
      return this.item[this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'];
    },
    isReadonly() {
      const isFrom = this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM;
      const ipSubmitFlg = _.get(this.localValue, `${this.tableName}[${this.arrayName}][${this.selectIndex}]['ipreqInformationGrp'].ipSubmitFlg`);
      const actionType = _.get(this.localValue, `${this.tableName}[${this.arrayName}][${this.selectIndex}]['ipreqInformationGrp'].actionType`);
      // From,To共通でSubmit:ONの場合のみ編集可能
      if (ipSubmitFlg === IP_SUBMIT_FLG.OFF) {
        return true;
      }
      if (isFrom) {
        // From側の場合、Action:Cancelの場合は編集不可
        // Message通信欄のみCancel時にも編集可能 https://tradewaltz.backlog.com/view/PB-789
        if (this.groupName === 'ipreqInformationGrp' && this.keyName === 'message') {
          return false;
        } else {
          return actionType === IP_REQUEST_TYPE.CANCEL;
        }
      } else {
        // To側の場合、Actionに何が入っていても、保険会社ロールのみ編集可能、保険会社ロール以外(FWD)は編集不可
        return !this.isInsurance;
      }
    },
    SYS_MST_UNIT() {
      return this.$store.getters.getSysMstUnit;
    },
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    SYS_MST_PORT_AND_PLACE() {
      return this.$store.getters.getSysMstPortAndPlace;
    },
    rules() {
      let ignore = false;
      if (this.s.processType === PROCESS_TYPE.FDCOR && this.$parent.ignore) {
        ignore = true;
      }
      const rules = [
        {
          required: this.s.draftValid || ignore ? false : this.item.validationId.includes('tw-isNotEmpty'),
          lakeelMessageSourceFlg: true,
          validationId: this.s.draftValid || ignore ? _.reject(this.item.validationId, s => s === 'tw-isNotEmpty') : this.item.validationId,
          validator: this.s.onEventValidation,
          trigger: ['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code) ? 'change' : 'blur'
        }
      ];
      if (this.item.tsValidationId) {
        const tsValidationIds = _.map(this.item.tsValidationId, tsValidationId => {
          return {
            required: this.s.draftValid ? false : tsValidationId.startsWith('ts-isNotEmpty'),
            validator: this.s.tsValidation,
            tsValidationId: this.s.draftValid && (tsValidationId.startsWith('ts-isNotEmpty') && tsValidationId !== 'ts-isNotEmptyInvoiceAmountOrIpreqCargoAmount') ? undefined : tsValidationId,
            trigger: ['select', 'radio', 'checkbox', 'unit', 'currencyId'].includes(this.item.inputType) || ['SYS_MST_CONTAINER_TYPE', 'SYS_MST_PORT_AND_PLACE', 'SYS_MST_COUNTRY'].includes(this.item.code) ? 'change' : 'blur'
          };
        });
        return [...tsValidationIds, ...rules];
      }
      return rules;
    },
    getMaxLength() {
      return getMaxLength(this.item.validationId);
    },
    // 連番項目を可変にし追加可能にする対応--------------------------
    isSerialGrp() {
      return this.serialItems ? Object.keys(this.serialItems).length > 0 : false;
    },
    isSerialItem() {
      if ((this.serialIndex && this.itemIndex) || this.serialIndex === 0) return this.serialIndex >= this.itemIndex;
      else return true;
    },
    serialIndex() {
      if (this.serialIndexes && Object.keys(this.serialIndexes).length > 0) return getSerialIndex(this.serialIndexes, this.serialGroupName ? this.serialGroupName : this.item.key);
      else return false;
    },
    itemIndex() {
      if (this.serialItems && this.isSerialGrp) return getItemIndex(this.serialItems, this.item.key);
      else return false;
    },
    isAddSerial() {
      return this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex < getMaxIndex(this.serialItems, this.item.key);
    },
    isRemoveSerial() {
      return this.isSerialGrp && this.itemIndex === this.serialIndex && this.serialIndex > 0 && !(this.model || this.model === 0);
    },
    serialGroupName() {
      return _.get(this.serialItems[getKey(this.item.key)], 'groupName') || false;
    },
    isNestItem() {
      if (!this.serialItems) {
        return true;
      }
      const nestGroupName = _.get(this.serialItems[getKey(this.item.key)], 'nestGroupName');
      if (nestGroupName && this.serialIndexes && Object.keys(this.serialIndexes).length > 0) {
        const subIndex = Number(getKey(this.item.key).replace(/[^0-9]/g, '')) - 1;
        return getSerialIndex(this.serialIndexes, nestGroupName) >= subIndex;
      } else return true;
    },
    isGroupButton() {
      return _.get(this.serialItems[getKey(this.item.key)], 'groupButton');
    },
    invalid() {
      if (_.isEmpty(this.lineErrors)) {
        return false;
      }
      return _.some(this.lineErrors, e => {
        // if (e === `${this.tableName}.${this.arrayName}[${this.selectIndex}].${this.groupName}.${this.keyName}`) {
        //   console.log(this.lineErrors)
        // }
        return e === `${this.tableName}.${this.arrayName}[${this.selectIndex}].${this.groupName}.${this.keyName}`;
      });
    },
  },
  mounted() {
    // 画面生成時、エラーがある場合はバリデーションを実行する
    if (this.invalid) {
      // HACK: 同時に複数の単体バリデーションを走らせると、lakeel-validation-apiへのリクエストのwidgetTargetが重複して送信されてしまうため、1つずつバリデーションをずらして実行する
      const lineIndex = _.findIndex(this.lineErrors, e => {
        return e === `${this.tableName}.${this.arrayName}[${this.selectIndex}].${this.groupName}.${this.keyName}`;
      });
      const validationTiming = lineIndex * 10;
      // console.log(this.lineErrors.length)
      // console.log(validationIndex, validationTiming);
      setTimeout(() => {
        this.validate();
      }, validationTiming);
    }
  },
  methods: {
    // バリデーション実行
    validate() {
      this.s.$refs.form.validateField(`${this.tableName}.${this.arrayName}[${this.selectIndex}].${this.groupName}.${this.keyName}`);
    },
    // スコープのモデルに値をセットします
    setValue(value) {
      this.s.setArrayValue(this.tableName, this.arrayName, this.selectIndex, this.groupName, this.keyName, value);
    },
    // スコープ外のモデルに値をセットします
    setTargetValue(target, value) {
      this.s.setArrayValue(this.tableName, this.arrayName, this.selectIndex, this.groupName, target, value);
    },
    getTarget(target = this.item) {
      return _.last(target.unitTarget.split('.'));
    },
    nullFormat(value) {
      if (value === null || '') {
        return undefined;
      }
      if (_.isNaN(Number(value))) {
        return undefined;
      }
      return Number(value);
    },
    onUnitChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_UNIT, { value: value }), 'code');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
      // 単位変更時にバリデーションを実行
      this.validate();
    },
    onCurrencyChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_CURRENCY, { symbol: value }), 'alphabeticCode');
      const idTarget = _.find(this.$parent.group.children, { key: this.getTarget() });
      const unitTarget = this.getTarget(idTarget);
      if (unitId) {
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
      // 単位変更時にバリデーションを実行
      this.validate();
    },
    onPlaceChange(value) {
      const unitId = _.get(_.find(this.SYS_MST_PORT_AND_PLACE, { name: value }), 'code');
      const unitTarget = this.getTarget();
      if (unitId || unitId === 0) {
        // IP発行依頼の港湾名は全て最大36桁のため切り捨てる
        const substringValue  = value.substring(0, 36);
        this.setValue(substringValue);
        this.setTargetValue(unitTarget, unitId);
      } else {
        this.setTargetValue(unitTarget, null);
      }
    },
    // onCountryChange(value) {
    //   const unitId = _.get(_.find(this.SYS_MST_COUNTRY, { name: value }), 'countryCd');
    //   const unitTarget = this.getTarget();
    //   if (unitId || unitId === 0) {
    //     this.setTargetValue(unitTarget, unitId);
    //   } else {
    //     this.setTargetValue(unitTarget, null);
    //   }
    // },
    onRadioClick(val, key) {
      if (this.isReadonly) return;
      if (this.localValue[this.tableName][this.arrayName][this.selectIndex][this.groupName][key] === val) {
        this.setValue(null);
      } else {
        this.setValue(val);
        if (this.keyName === 'tradeCategory') {
          // Trade Category変更時、転記処理を実行
          this.onChangeTradeCategory(val);
        }
      }
    },
    // Trade Category変更時の転記処理
    onChangeTradeCategory(val) {
      // 選択肢が「Offshore」の場合は転記しない
      if (val && val !== REQUEST_IP_TRADE_CATEGORY.OFFSHORE) {
        // 転記処理
        changeTradeCategory(val, this.selectIndex, this.s.cloneItems);
        // 転記後にバリデーションを実行
        const validationTargets = ['ipreqQuantity1_01', 'ipInvoiceAmount'];
        _.forEach(validationTargets, target => {
          const validationTiming = target.index * 10;
          setTimeout(() => {
            this.s.$refs.form.validateField(`${this.tableName}.${this.arrayName}[${this.selectIndex}].ipreqInformationGrp.${target}`);
          }, validationTiming);
        });
      }
    },
    // 連番項目を追加・削除します
    addIndex(num) {
      this.$emit('add-index', this.keyName, num);
    },
    onDateChange,
  }
};
</script>

<style lang="scss" scoped>
.tw_process_group_list_edit_item {
  // margin-bottom: 24px;
  &.border_top {
    border-top: 1px solid #EDEDF4;
    padding-top: 20px;
  }

  &.full {
    width: 100%;

    ::v-deep .el-form-item__content .el-form-item__error {
      max-width: 100%;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  ::v-deep .el-form-item {
    margin-bottom: 0;
    display: flex;

    label.el-form-item__label {
      display: block;
      width: 224px;
      font-size: 12px;
      line-height: 18px;
      color: #9191a4;
      margin-right: 8px;
      padding-top: 2px;
      flex-shrink: 0;
    }

    &.with_id label.el-form-item__label {
      position: relative;
    }

    &.with_id label.el-form-item__label:before {
      position: absolute;
      top: 0;
      left: 38px;
      font-size: 12px;
      font-weight: bold;
      color: white;
      line-height: 1;
      padding: 4px 8px;
      border-radius: 10px;
      background: #5185c5;
      content: 'with ID';
    }

    &.with_id.is-required label.el-form-item__label:before {
      position: absolute;
      top: 20px;
      left: 0;
    }
  }

  ::v-deep .el-form-item__content {
    font-size: 14px;
    line-height: 20px;
    color: #3e3a39;
    vertical-align: top;
    flex: 1;
  }

  ::v-deep textarea {
    resize: none;
  }

  .el-select {
    width: 320px;
  }

  .el-select.unit {
    width: 100px;
    margin-left: 12px;
  }

  // .el-input-number {
  //   width: 188px;
  // }

  .el-radio-group .el-radio.is-bordered:last-child {
    margin-right: 0;
  }

  .warning ::v-deep .el-input__inner,
  .hide_error.warning ::v-deep .el-input__inner {
    border-color: $color_draft;
  }

  .warning_message {
    // height: 18px;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    padding-left: 16px;
    color: $color_draft;
    background: url(../../assets/images/icons/icon_alert_warn.svg) no-repeat 0 2px;
    background-size: 14px auto;

    &.measurement {
      max-width: 320px;
    }
  }

  &.remove_serial {
    .el-input,
    .el-select {
      width: calc(320px - 20px) !important;
    }
    .el-textarea,
    .text_full {
      width: calc(100% - 20px) !important;
    }
    .remove_button {
      cursor: pointer;
      width: 20px;
      vertical-align: middle;
    }
    &.full {
      .remove_button {
        margin-bottom: 16px;
      }
    }
  }
  &.add_serial {
    .el-input,
    .el-select {
      width: calc(320px - 48px) !important;
    }
    .el-textarea,
    .text_full {
      width: calc(100% - 48px) !important;
    }
    .add_button {
      vertical-align: initial;
      margin: 0 0 0 8px;
      padding-left: 9px;
      padding-right: 1px;
      &.group_button {
        border-radius: 10px;
      }
    }
    &.remove_serial {
      .el-input,
      .el-select {
        width: calc(320px - 68px) !important;
      }
      .el-textarea,
      .text_full {
        width: calc(100% - 68px) !important;
      }
    }
    &.full {
      .add_button {
        margin-bottom: 8px;
      }
      .remove_button {
        margin-bottom: 6px;
      }
    }
  }

  .el-form-item {
    min-width: 462px;
    margin-bottom: 24px;
    // width: 50%;
    display: flex;
    padding-right: 16px;

    &:last-child {
      margin-bottom: 20px;
    }

    &.full {
      width: 100%;
    }

    ::v-deep label.el-form-item__label {
      display: block;
      width: 118px;
      font-size: 12px;
      line-height: 18px;
      color: #9191a4;
      margin-right: 8px;
      padding-top: 2px;
    }
  }

  ::v-deep .el-form-item__content {
    font-size: 14px;
    line-height: 20px;
    color: #3e3a39;
    vertical-align: top;
    flex: 1;

    .el-form-item__error {
      position: relative;
      top: 0;
      max-width: 320px;
    }
  }

  ::v-deep textarea {
    resize: none;
  }

  .el-select {
    width: 320px;
  }

  .el-select.unit {
    width: 90px;
    margin-left: 12px;
  }

  .el-select.packing {
    margin-left: 12px;
  }

  .el-input-number.narrow,
  .tw_input_number_string.narrow {
    width: 218px;
  }

  .el-input-number,
  .el-input-number.amount {
    width: 320px;
  }

  .warning ::v-deep .el-input__inner {
    border-color: $color_draft;
  }
}
</style>
