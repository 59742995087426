<template>
  <div v-if="visible" ref="table" class="tw_process_list" :class="{open: open, has_error: isEditMode && tableHasError}">
    <tr class="inner" @click="toggle">
      <td class="arrow">
        <img src="@/assets/images/icons/accordion_arrow.svg">
      </td>
      <td class="table_name">Insurance Policy Request</td>
      <td class="subtask">
        <!-- <div v-if="!table.variableName.includes('IpreqLine') && !subtaskHide"><tw-subtask-icon :number="groupCount" /></div> -->
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="open" class="collapse">
            <template v-if="table.variableName === 'processSeparate'">
              <div v-if="isEditMode" style="display: flex;justify-content: flex-end;padding-top: 24px;">
                <tw-button v-if="isFrom" type="secondary" size="medium" icon="plus" @click="newIpreq">New</tw-button>
                <tw-button v-if="isFrom" type="secondary" size="medium" @click="copyIpreq" :disabled="!(selectIpreq && (selectIndex === 0 || selectIndex))">Copy</tw-button>
              </div>

              <div style="width: 100%;font-size: 14px;">
                <span v-if="isEditMode && localValue.processSeparate.ipreqList && localValue.processSeparate.ipreqList.length">{{ localValue.processSeparate.ipreqList.length }} Records</span>
                <span style="display: block; margin-top: 16px;" v-else-if="s.res.processSeparate.ipreqList && s.res.processSeparate.ipreqList.length">{{ s.res.processSeparate.ipreqList.length }} Records</span>
              </div>

              <!-- 編集 -->
              <template v-if="isEditMode && localValue.processSeparate">
                <div class="ip_request_table_edit">
                  <table class="goods_table fixed_header" ref="fixed_header" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="(column, index) in ipRequestTable" :key="index" :class="{ required: column.required }" :style="{'width': column.width + 'px' || 'auto', 'max-width': column.width + 'px' || 'auto'}">{{column.label}}</th>
                      </tr>
                    </thead>
                  </table>
                  <table v-if="isShow && drawerShow" cellspacing="0" cellpadding="0" style="background-color: white;">
                    <TwTableScrollerList
                      class="ip_request_table_scroller"
                      ref="ip_request_table_scroller"
                      :items="localValue.processSeparate.ipreqList"
                      :item-size="40"
                      :keeps="12"
                      :edit="true"
                      key-field="keyField"
                      maxWidth="944px"
                      @headerScroll="headerScroll"
                    >
                      <template v-slot="{ item, index }">
                        <thead slot="before"></thead>
                        <TwProcessGroupListEditIpRequestTable
                          :item="item"
                          :index="index"
                          :selected="index === selectIndex"
                          :selectIndex="selectIndex"
                          :ipRequestTable="ipRequestTable"
                          :hasError="hasError(['processSeparate.ipreqList'])"
                          v-model="localValue"
                          @click="selectRow(index)"
                          @delete-ipreq="removeIpreqLine"
                          @selectRow="selectRow"
                          :ref="`ipreq_row_${index}`"
                          :isFrom="isFrom"
                          :isInsurance="isInsurance"
                        />
                      </template>
                    </TwTableScrollerList>
                  </table>
                </div>
                <template v-if="selectIpreq">
                  <template v-for="(item, index) in localValue.processSeparate.ipreqList">
                    <div v-if="index === selectIndex" :key="index">
                      <TwProcessGroupListIpRequest
                        v-for="group in ipRequestData.groups"
                        :tableName="table.variableName"
                        :group="group"
                        :key="`edit_${group.variableName}`"
                        :isEditMode="isEditMode"
                        v-model="localValue"
                        :selectIndex="selectIndex"
                        :selectIpreq="selectIpreq"
                        :isInsurance="isInsurance"
                      />
                    </div>
                  </template>
                </template>
              </template>

              <!-- 閲覧 -->
              <template v-if="!isEditMode && s.res.processSeparate">
                <div v-if="!isEditMode && s.res.processSeparate" :style="`width: 100%; max-width: calc(${tabWidth}px - 46px)`" class="ip_request_table">
                  <table class="goods_table fixed_header" ref="fixed_header" cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th v-for="column in ipRequestPreviewTable" :key="column.label" style="padding: 4px 0" :style="{'width': column.width + 'px' || 'auto', 'max-width': column.width + 'px' || 'auto'}">
                          <div style="padding: 0 5px">{{column.label}}</div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <table v-if="isShow" cellspacing="0" cellpadding="0">
                    <TwTableScrollerList
                      class="ip_request_table_scroller"
                      ref="ip_request_table_scroller"
                      :items="s.res.processSeparate.ipreqList"
                      :item-size="40"
                      :keeps="10"
                      key-field="keyField"
                      :maxWidth="`${tabWidth - 46}px`"
                      @headerScroll="headerScroll"
                    >
                      <template v-slot="{ item, index }">
                        <thead slot="before"></thead>
                        <TwProcessGroupListPreviewIpRequestTable
                          :row="item"
                          :selected="index === selectIndex"
                          :selectIndex="selectIndex"
                          :schemas="ipRequestPreviewTable"
                          @click="selectRow(index)"
                        />
                      </template>
                    </TwTableScrollerList>
                  </table>
                </div>
                <template v-if="selectIpreq">
                  <template v-for="(item, index) in s.res.processSeparate.ipreqList">
                    <div v-if="index === selectIndex" :key="index">
                      <TwProcessGroupListIpRequest
                        v-for="group in ipRequestData.groups"
                        :tableName="table.variableName"
                        :group="group"
                        :key="group.variableName"
                        :isEditMode="isEditMode"
                        v-model="localValue"
                        :selectIndex="selectIndex"
                        :selectIpreq="selectIpreq"
                      />
                    </div>
                  </template>
                </template>
              </template>
            </template>
          </div>
        </transition>
      </td>
    </tr>
  </div>
</template>

<script>
import _ from 'lodash';
import { COMPANY_ROLE_CD, RESPONSIBLE_FLG, PROCESS_TYPE } from 'lib-tw-common';
// import TwSubtaskIcon from '@/components/atoms/TwSubtaskIcon.vue';
import TwButton from '@/components/atoms/TwButton';
import TwTableScrollerList from '@/components/atoms/TwTableScrollerList';
import TwProcessGroupListIpRequest from '@/components/molecules/TwProcessGroupListIpRequest';
import { collapseTransition } from '@/utils/nextFrame';
import ipRequestTableSchema from '@/dictionaries/ipRequestTableSchema.json';
import { createIpRequestData, copyIpRequestData } from '@/utils/ipRequestUtils';
import { mapState } from 'vuex';

export default {
  name: 'TwIpRequestProcessList',
  inject: ['s'],
  provide() {
    return {
      tab: this.tab
    };
  },
  props: {
    table: Object,
    isEditMode: Boolean,
    value: Object,
    initialOpen: {
      type: Boolean,
      default: false
    },
    subtaskHide: Boolean,
    tab: Number
  },
  components: {
    // TwSubtaskIcon,
    TwButton,
    TwTableScrollerList,
    TwProcessGroupListEditIpRequestTable: () => import('@/components/molecules/TwProcessGroupListEditIpRequestTable'),
    TwProcessGroupListPreviewIpRequestTable: () => import('@/components/molecules/TwProcessGroupListPreviewIpRequestTable'),
    TwProcessGroupListIpRequest
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.isEditMode || this.initialOpen,
      selectIndex: null,
      selectIpreq: null,
      tableHasError: false,
      PROCESS_TYPE,
      tabWidth: 920, // 親のタブの幅
    };
  },
  computed: {
    // 保険会社ロールを持つかどうか
    ...mapState(['companyRoles']),
    isInsurance() {
      return _.some(this.companyRoles, cd => {
        return COMPANY_ROLE_CD.MARINE_INSURANCE === cd;
      });
    },
    isShow() {
      if (this.isEditMode) {
        return _.get(this, 'localValue.processSeparate.ipreqList.length', 0);
      } else {
        return _.get(this, 's.res.processSeparate.ipreqList.length', 0);
      }
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    isFrom() {
      return this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM;
    },
    visible() {
      if (!this.groupCount) {
        return false;
      }
      if (!this.isEditMode) {
        return true;
      }
      return _.some(this.table.groups, group => {
        return _.some(group.children, item => {
          return item[this.isFrom ? 'from' : 'to'] && item.controlType !== false;
        });
      });
    },
    drawerShow() {
      return this.$store.state.drawerShow;
    },
    hasError() {
      // console.log(this.s.errors)
      return names => {
        const filtered = _.filter(this.s.errors, e => {
          // console.log(e)
          return _.some(names, name => {
            if (_.isString(e)) {
              // console.log(name)
              return e.startsWith(name + '[');
            }
            return false;
          });
        });
        // console.log(filtered)
        return filtered;
      };
    },
    groupCount() {
      return _.size(this.table.groups);
    },
    // IP発行依頼個別テーブルデータ
    ipRequestPreviewTable() {
      return _.reject(this.getTableData(ipRequestTableSchema), { key: 'delete' });
    },
    // IP発行依頼個別テーブルデータ
    ipRequestTable() {
      return this.getTableData(ipRequestTableSchema);
    },
    // IP発行依頼個別データ（表以外の項目）
    ipRequestData() {
      let ipRequestData = _.cloneDeep(this.table);
      ipRequestData.groups = _.map(ipRequestData.groups, group => {
        group.children = _.reject(group.children, item => {
          return _.some(ipRequestTableSchema, column => {
            // 表レコードにも表示しつつ、個別データにも表示させたい項目は、isDetailViewをtrueに設定する
            return column.key === item.key && !column.isDetailView;
          });
        });
        if (group.variableName === 'ipreqInformationGrp') {
          // Ph7UAT要望対応 TradeCategoryを最上段に表示
          group.children = _.sortBy(group.children, item => {
            return item.key === 'tradeCategory' ? 0 : 1;
          });
        }
        return group;
      });
      return ipRequestData;
    },
  },
  watch: {
    drawerShow(val) {
      if (!val && this.isEditMode) {
        this.resetSelect();
        this.headerScroll(0);
      }
    },
    's.errors': {
      handler() {
        if (!this.visible || !this.$refs.table) {
          return false;
        }
        requestAnimationFrame(() => {
          this.tableHasError = _.some(_.get(this, 's.errors', []), e => {
            return _.isString(e) && e.startsWith('processSeparate.ipreqList' + '[');
          });
        });
      },
      deep: true
    }
  },
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
  },
  mounted() {
    window.addEventListener('resize', this.onResize, false);
    this.onResize();
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.tabWidth = _.get(this, 's.$refs.processDetail.$refs.tabs.clientWidth', 920);
    },
    toggle() {
      this.open = !this.open;
    },
    selectRow(index) {
      this.selectIndex = index;
      if (this.isEditMode) {
        this.selectIpreq = _.get(this, `localValue.processSeparate.ipreqList[${index}]`);
      } else {
        this.selectIpreq = _.get(this, `s.res.processSeparate.ipreqList[${index}]`);
      }
    },
    newIpreq() {
      const ipreqList = _.get(this, 'localValue.processSeparate.ipreqList', []);
      const newItem = createIpRequestData(this.localValue);
      if (ipreqList.length) {
        // NOTE: 多量のリストを追加・削除する際は、pushで行うとメモリを多量に消費するため、$setや$deleteを使う
        this.$set(this.localValue.processSeparate.ipreqList, ipreqList.length, newItem);
        // this.localValue.processSeparate.ipreqList.push(newItem);
      } else {
        this.localValue.processSeparate.ipreqList = [newItem];
      }
    },
    copyIpreq() {
      const ipreqList = _.get(this, 'localValue.processSeparate.ipreqList', []);
      const copyItem = copyIpRequestData(this.selectIpreq);
      this.$set(this.localValue.processSeparate.ipreqList, ipreqList.length, copyItem);
      // this.localValue.processSeparate.ipreqList.push(copyItem);
    },
    resetSelect() {
      this.selectIndex = null;
      this.selectIpreq = null;
    },
    removeIpreqLine(index) {
      this.$store
        .dispatch('SHOW_CONFIRM', `Are you sure you want to delete the this data?`)
        .then(() => {
          this.$delete(this.localValue.processSeparate.ipreqList, index);
          // this.localValue.processSeparate.ipreqList.splice(index, 1);
          this.resetSelect();
          requestAnimationFrame(() => {
            this.s.$refs.form.clearValidate();
            this.s.errors = [];
          });
        })
        .catch(e => {
          console.log(e);
        });
    },
    openAll() {
      this.open = true;
      this.$emit('openAll');
    },
    closeAll() {
      this.open = false;
      this.$emit('closeAll');
    },
    getTableData(schema) {
      return _.compact(
        _.map(schema, column => {
          const table = _.find(this.s.schemas.tables, { variableName: column.table });
          if (!table) {
            return null;
          }
          const group = _.find(table.groups, { variableName: column.group });
          let item = _.find(group.children, { key: column.key });
          if (column.key === 'ipSubmitFlg') {
            item = {
              required: false,
              from: true,
              to: false,
              hide: false,
              validationId: ['tw-isNumber', 'tw-isIntegerDigit2'],
              target: '',
              unitTarget: '',
              groupTarget: '',
              inputType: 'checkbox',
              label: 'Submit',
              key: 'ipSubmitFlg',
              compact: false,
              code: 'IP_SUBMIT_FLG',
              controlType: true,
              repeat: false
            };
          }
          return {
            key: column.key,
            table: column.table,
            array: 'ipreqList',
            group: column.group,
            item: item,
            parentGroup: group,
            width: column.width,
            label: column.label,
            readonly: column.readonly,
            align: column.align,
            dataType: column.dataType,
            required: column.required
          };
        })
      );
    },
    // ヘッダーの横スクロール制御
    headerScroll(scrollLeft) {
      // console.log(scrollLeft)
      this.$refs.fixed_header.style.left = -scrollLeft +'px'
    },
  }
};
</script>

<style lang="scss" scoped>
.ip_request_table,
.ip_request_table_edit {
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}
.ip_request_table_edit {
  max-width: 944px;
}
.tw_process_list {
  display: table-row-group;
  position: relative;
  background: $color_white;
  @include card_shadow;
  &.has_error {
    background: rgba($color_warning, 0.03);
    > tr.inner {
      background: rgba($color_warning, 0.1);
    }
  }
  &:first-child > tr > td {
    border: none !important;
  }
  .inner {
    display: table-row;
    cursor: pointer;
    td.arrow {
      width: 20px;
      vertical-align: middle;
      padding: 10px 0 10px 8px;
      border-top: 1px solid $color_gray_300;
      img {
        display: block;
        width: 12px;
        height: 12px;
        transition: transform 0.3s;
      }
    }
  }
  .table_name {
    width: 206px;
    vertical-align: middle;
    padding: 10px 8px 10px 16px;
    font-size: 16px;
    line-height: 24px;
    color: $color_gray_800;
    white-space: nowrap;
    border-top: 1px solid $color_gray_300;
    &.full {
      width: 100%;
      padding-bottom: 0;
    }
  }
  td.subtask {
    padding: 10px 0;
    vertical-align: middle;
    border-top: 1px solid $color_gray_300;
  }
  .collapse {
    padding: 0 22px 10px;
  }
  &.open {
    .inner td.arrow img {
      transform: rotate(90deg);
    }
    .inner {
      @include list_shadow;
    }
  }
}
.content-enter-active,
.content-leave-active {
  transition: height 0.3s;
}
.table_scroller {
  max-width: 100%;
}
table.goods_table {
  table-layout: fixed;
  width: 100%;
  // border: 1px solid #BFBFCD;
  border-collapse: collapse;
  background: #ffffff;
  margin-top: 24px;
  &.fixed_header {
    position: relative;
  }
  td.goods_line_table_inner {
    position: relative;
    img.close_times {
      position: absolute;
      right: 4px;
      top: 50%;
      margin-top: calc(13px - 10px);
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0;
    }
    table {
      table-layout: fixed;
      min-width: 100%;
      border: 0;
      border-collapse: collapse;
      th {
        border-top: 0 !important;
        padding: 4px 5px;
        white-space: normal;
      }
      td {
        border-top: 0 !important;
        border-bottom: 0 !important;
        padding: 0;
      }
      tr th:first-child,
      tr td:first-child {
        border-left: 0 !important;
      }
      tr th:last-child,
      tr td:last-child {
        border-right: 0 !important;
      }
    }
  }
  th {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
    padding: 4px 5px;
    border-left: 1px solid $color_gray_300;
    border-right: 1px solid $color_gray_300;
    background: $color_dark_blue;
    color: white;
    // font-weight: bold;
    &.required:after,
    > div.required:after {
      content: '*';
      color: #e00001;
      margin-left: 4px;
    }
  }
  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    padding: 0;
    border: 1px solid $color_gray_300 !important;
  }
  .el-form-item {
    width: 100%;
    padding-right: 0;
    margin: 0;
    ::v-deep {
      label.el-form-item__label {
        display: none;
      }
      .el-input,
      .el-select,
      .el-input-number,
      .el-input-number.amount {
        width: 100% !important;
      }
      .el-input-number .el-input__inner {
        text-align: right;
      }
      .el-input__inner {
        font-size: 12px;
        width: 100%;
        background: transparent;
        box-shadow: none;
        border-radius: 0;
        padding: 0 5px;
        &:not(:focus) {
          border: 0;
        }
      }
      .el-select {
        .el-input__inner {
          padding-right: 20px;
          overflow: hidden;
          white-space: nowrap;
        }
        .el-input__suffix {
          right: 0;
        }
      }
      .el-form-item__error {
        white-space: normal;
        font-size: 10px;
        left: 5px;
        margin-bottom: 5px;
      }
      .warning_message {
        font-size: 10px;
        background-position: 5px 2px;
        padding-left: 20px;
      }
    }
  }
}
</style>
