<template>
  <div class="tw_process_group_list_item_wrapper" v-if="isVisible && isSerialItem && isNestItem" :class="[{ full: item.inputType === 'text_full' || item.inputType === 'textarea' }]">
    <div class="tw_process_group_list_item">
      <div class="label">{{item.label}}</div>
      <template v-if="isInActiveTab">
        <div v-if="hasModel && item.inputType === 'date'" class="value">{{model | utcOffsetDateFormat}}</div>
        <div v-else-if="hasModel && item.inputType === 'dateNumber'" class="value">{{model | dateNumber}}</div>
        <div v-else-if="hasModel && item.inputType === 'quantity'" class="value">{{formatQuantity}}</div>
        <div v-else-if="hasModel && item.inputType === 'currency'" class="value">{{formatCurrency}}</div>
        <div v-else-if="hasModel && item.code === 'DELIVERY_TERMS'" class="value">{{DELIVERY_TERMS}}</div>
        <div v-else-if="hasModel && item.code === 'SYS_MST_PORT_AND_PLACE'" class="value">{{portAndPlace}}</div>
        <div v-else-if="hasModel && item.code === 'REQUEST_IP_TRADE_CATEGORY'" class="value">{{requestIpTradeCategory}}</div>
        <div v-else-if="hasModel && item.code === 'REQUEST_IP_DN_TYPE'" class="value">{{requestIpDnType}}</div>
        <div v-else-if="hasModel && item.code === 'IPDN_CONVEYANCE'" class="value">{{ipdnConveyance}}</div>
        <div v-else-if="hasModel && item.inputType === 'number'" class="value">{{number}}</div>
        <div v-else-if="hasModel && item.inputType === 'numberString'" class="value">{{numberString}}</div>
        <div v-else-if="hasModel && item.inputType === 'textarea'" class="value" v-html="sanitize(model)" />
        <div v-else class="value">{{model}}</div>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { DELIVERY_TERMS_VARIABLES, REQUEST_IP_TRADE_CATEGORY_VARIABLES, REQUEST_IP_DN_TYPE_VARIABLES, IPDN_CONVEYANCE_VARIABLES } from 'lib-tw-common';
import { formatQuantity, formatCurrency, formatNumber, formatNumberString } from '@/utils/searchUtil.js';
import { getKey, getSerialIndex, getItemIndex } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListItem',
  props: {
    item: Object,
    tableName: String,
    arrayName: String,
    groupName: String,
    keyName: String,
    value: Object,
    selectIndex: Number,
    serialIndexes: Object,
    serialItems: Object,
    isEditMode: Boolean
  },
  inject: ['s', 'tab', 'activeTab'],
  components: {},
  data() {
    return {};
  },
  computed: {
    model() {
      return _.get(this, `s.res[${this.tableName}][${this.arrayName}][${this.selectIndex}][${this.groupName}][${this.keyName}]`);
    },
    hasModel() {
      return this.model === 0 || this.model;
    },
    hasAnyModel() {
      const target = _.last(this.item.unitTarget.split('.'));
      return this.model === 0 || this.model || _.get(this, `s.res[${this.tableName}][${this.arrayName}][${this.selectIndex}][${this.groupName}][${target}]`);
    },
    number() {
      if (_.isNaN(Number(this.model))) {
        return this.model;
      }
      return formatNumber(this.model, this.item.validationId);
    },
    numberString() {
      return formatNumberString(this.model);
    },
    SYS_MST_CURRENCY() {
      return this.$store.getters.getSysMstCurrency;
    },
    formatQuantity() {
      const target = _.last(this.item.unitTarget.split('.'));
      return formatQuantity(this.model, _.get(this, `s.res[${this.tableName}][${this.arrayName}][${this.selectIndex}][${this.groupName}][${target}]`), undefined);
    },
    formatCurrency() {
      const target = _.last(this.item.unitTarget.split('.'));
      const currencyUnit = _.get(this, `s.res[${this.tableName}][${this.arrayName}][${this.selectIndex}][${this.groupName}][${target}]`);
      const currencyId = _.get(_.find(this.SYS_MST_CURRENCY, { symbol: currencyUnit }), 'alphabeticCode');
      const currency = this.$store.getters.getCurrency(currencyId);
      if (currency) {
        return formatCurrency(this.model, currencyUnit, currency.minorUnit);
      }
      return formatCurrency(this.model, currencyUnit);
    },
    DELIVERY_TERMS() {
      return _.get(_.find(DELIVERY_TERMS_VARIABLES, { code: this.model }), 'label') || '';
    },
    country() {
      return this.model;
    },
    portAndPlace() {
      return this.model;
    },
    requestIpTradeCategory() {
      return _.get(_.find(REQUEST_IP_TRADE_CATEGORY_VARIABLES, { code: this.model }), 'label') || '';
    },
    requestIpDnType() {
      return _.get(_.find(REQUEST_IP_DN_TYPE_VARIABLES, { code: this.model }), 'label') || '';
    },
    ipdnConveyance() {
      return _.get(_.find(IPDN_CONVEYANCE_VARIABLES, { code: this.model }), 'label') || '';
    },
    isVisible() {
      return !this.item.hide && this.item.code !== 'SYS_MST_UNIT' && this.item.code !== 'SYS_MST_CURRENCY' && !(this.item.code === 'SYS_MST_COUNTRY' && this.item.compact) && !(this.groupName === 'airGrp' && this.item.code === 'SYS_MST_PORT_AND_PLACE' && this.item.compact);
    },

    // 連番項目を可変にし追加可能にする対応--------------------------
    isSerialItem() {
      if ((this.serialIndex && this.itemIndex) || this.serialIndex === 0) return this.serialIndex >= this.itemIndex;
      else return true;
    },
    serialIndex() {
      if (this.serialIndexes && Object.keys(this.serialIndexes).length > 0) return getSerialIndex(this.serialIndexes, this.serialGroupName ? this.serialGroupName : this.item.key);
      else return false;
    },
    itemIndex() {
      if (this.serialItems && Object.keys(this.serialItems).length > 0) return getItemIndex(this.serialItems, this.item.key);
      else return false;
    },
    serialGroupName() {
      return _.get(this.serialItems[getKey(this.item.key)], 'groupName') || false;
    },
    isNestItem() {
      const nestGroupName = _.get(this.serialItems[getKey(this.item.key)], 'nestGroupName');
      if (nestGroupName && this.serialIndexes && Object.keys(this.serialIndexes).length > 0) {
        const subIndex = Number(getKey(this.item.key).replace(/[^0-9]/g, '')) - 1;
        return getSerialIndex(this.serialIndexes, nestGroupName) >= subIndex;
      } else return true;
    },
    // アクティブなタブ配下にいるか
    isInActiveTab() {
      if (!this.tab && this.tab !== 0) {
        return true;
      }
      return _.get(this.activeTab, 'value') === this.tab;
    }
  },
  methods: {
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    getTargetValue() {
      const target = _.last(this.item.unitTarget.split('.'));
      return _.get(this.s, `res[${this.tableName}][${this.groupName}][${target}]`);
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_process_group_list_item_wrapper {
  width: 50%;
  min-width: 300px;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &.full {
    width: 100%;
  }
}
.tw_process_group_list_item {
  // width: 50%;
  // min-width: 300px;
  // margin-bottom: 12px;
  display: flex;
  padding-right: 16px;

  &.full {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 118px;
    font-size: 12px;
    line-height: 18px;
    color: #9191a4;
    margin-right: 8px;
    vertical-align: top;
    padding-top: 2px;
  }

  .value {
    font-size: 14px;
    line-height: 20px;
    color: #3e3a39;
    vertical-align: top;
    flex: 1;
  }
}
</style>
